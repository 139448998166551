/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="js-anim  --anim3 --anim-s4 pb--40 pt--80" anim={"3"} name={"einleitung"} animS={"4"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--60" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box w--400" style={{"maxWidth":1110}} content={"Sorgfältig Außergewöhnliche Blumen und Blüten anbauen"}>
              </Title>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/1fd2dfd170f14244acf45f8fe698f706.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"artikel"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"17€"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/de/sache"} content={"Mehr"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--1 pb--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":869}}>
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant)'>Pflege mit Liebe</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--60 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":869}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":869}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--80 pt--80" name={" durchsuchen"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--80 pt--80" anim={"2"} animS={"4"}>
              
              <Title className="title-box" content={"Wählen Sie Ihre aus"}>
              </Title>

              <Button className="btn-box btn-box--cbtn1 btn-box--shape5 btn-box--cColor1 lh--2" content={"Produktkatalog"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Webproduktion stránek</a><br>Oeder Weg 22</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/cd8db1a198b540c896a32b45d514eef3.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--right" content={"<span style='color: var(--color-dominant)'>+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}